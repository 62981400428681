import React, { Dispatch, useContext, useEffect, useReducer } from 'react'
import { Device, Call as TwilioCall, TwilioError } from '@twilio/voice-sdk'
import * as Sentry from '@sentry/react'
import backend from '../../backend'
import IdleTimer from './IdleTimer'
import { useNotification } from '../../hooks/notification'
import { TenantConfig, useTenantConfig } from '../../hooks/TenantConfig'
import { UserLead } from '../../types/user_lead'
import { useAuth } from '../../components/AuthProvider/auth_provider'
import { useScreenSize } from '../../hooks/useScreenSize'
import { jwtDecode } from 'jwt-decode'
import { voipReducer, VoipActionType, VoipAction } from './VoipReducer'
import { DevModeInboundCall } from './DevModeInboundCall'
import { DevModeOutboundCall } from './DevModeOutboundCall'
import { useEnabledCallCampaign } from 'src/hooks/queries/useEnabledCallCampaign'
import { useCampaigns } from 'src/hooks/campaigns'
import { usePusherEvent } from 'src/hooks/pusher'

export type VoipState = {
  /** Whether VoIP is allowed for the current user's browser. VoIP is **NOT ALLOWED** on mobile devices unless voice is enabled in the tenant config.
   *
   * NOTE: just because VoIP is allowed doesn't mean that a VoIP connection will be established. There are additional conditions that must be met that depend on the current VoIP state. E.g. if the user is in dev mode or if the user has an active call campaign.
   */
  // TODO - greatly simplify the VoIP window by adding a callStatus property
  voipAllowed: boolean
  voipShouldBeConnected: boolean
  voipIsConnected: boolean
  availableInputDevices: MediaDeviceInfo[]
  availableOutputDevices: MediaDeviceInfo[]
  selectedInputDeviceId?: string
  selectedOutputDeviceId?: string
  devMode: boolean
  shouldRefreshToken: boolean
  inboundCallAccepted: boolean
  microphonePermissionGranted: boolean
  device?: Device
  inboundCall?: TwilioCall
  outboundCall?: TwilioCall
  userLead?: UserLead
  error?: string
}

const getDefaultDevMode = () => {
  let defaultDevMode = process.env.NODE_ENV === 'development'
  if (process.env.REACT_APP_DEFAULT_DEV_MODE === 'false') defaultDevMode = false
  const sessionDevMode = sessionStorage.getItem('voip-dev-mode')
  if (sessionDevMode === 'true') defaultDevMode = true
  if (sessionDevMode === 'false') defaultDevMode = false
  return defaultDevMode
}

const defaultVoipState: VoipState = {
  voipAllowed: false,
  voipShouldBeConnected: false,
  voipIsConnected: false,
  availableInputDevices: [],
  availableOutputDevices: [],
  selectedInputDeviceId: localStorage.getItem('last-selected-input-device-id') || undefined,
  selectedOutputDeviceId: localStorage.getItem('last-selected-output-device-id') || undefined,
  devMode: getDefaultDevMode(),
  inboundCallAccepted: false,
  shouldRefreshToken: false,
  microphonePermissionGranted: false,
}

type VoipContext = {
  voipState: VoipState
  voipDispatch: Dispatch<VoipAction>
  makeOutboundCall: ({
    userLead,
    agentTwilioNumber,
    userLeadNumber,
  }: {
    userLead: UserLead
    agentTwilioNumber: string
    userLeadNumber: string
  }) => void
  triggerDevModeInboundCall: () => void
}

const voipContext = React.createContext<VoipContext>({
  voipState: defaultVoipState,
  voipDispatch: () => {},
  makeOutboundCall: () => {},
  triggerDevModeInboundCall: () => {},
})
export const useVoip = () => useContext(voipContext)

/**
 * Fetches a new VoIP token from our API
 */
const getNewVoipToken = async (): Promise<string> => {
  const { body } = await backend.get('/voip/token')
  const token = body.token
  Sentry.captureMessage('Generate new VoIP token', { level: 'info', extra: { token } })
  localStorage.setItem('voip-device-token', token)
  return token
}

const voipTokenBelongsToCurrentUser = ({ voipToken }: { voipToken: string }): boolean => {
  const authToken = localStorage.getItem('token')
  if (!authToken) return false // User is not logged in

  const decodedAuthToken = jwtDecode(authToken)
  const decodedVoipToken = jwtDecode(voipToken)

  // @ts-expect-error FIXME
  return decodedVoipToken.grants.identity === decodedAuthToken.id
}

/**
 * Checks if the token is properly formatted, not expired, and belongs to the current user
 */
const voipTokenIsValid = ({ voipToken }: { voipToken: string }): boolean => {
  try {
    if (!voipTokenBelongsToCurrentUser({ voipToken })) return false

    // Confirm that the token is not expired
    const decodedVoipToken = jwtDecode(voipToken)
    const exp = decodedVoipToken.exp
    return typeof exp === 'number' && exp > Date.now() / 1000
  } catch (e) {
    console.error('Error decoding token', e)
    return false
  }
}

/**
 * Fetches a VoIP token from local storage, if it exists, if not, fetches a new one from our API
 */
const getVoipToken = async (): Promise<string> => {
  const voipToken = localStorage.getItem('voip-device-token')
  if (voipToken && voipTokenIsValid({ voipToken })) {
    Sentry.captureMessage('Use existing VoIP token from session storage', {
      level: 'info',
      extra: { token: voipToken },
    })

    return voipToken
  }
  const newToken = await getNewVoipToken()
  return newToken
}

/**
 * Initializes the Twilio device and adds several event listeners to it
 */
const initDevice = async ({
  dispatch,
  tenantConfig,
}: {
  dispatch: React.Dispatch<VoipAction>
  tenantConfig: TenantConfig
}) => {
  const token = await getVoipToken()
  Sentry.setContext('deviceToken', { token })
  const device = new Device(token, {
    logLevel: 1,
    enableImprovedSignalingErrorPrecision: true,
  })

  const browserNotSupportedErrorMessage =
    'Your browser is not supported. Please use the latest version of Chrome, Safari, Firefox, or Edge to receive calls.'
  const connectionErrorMessage =
    tenantConfig.voip.enabled && tenantConfig.voip.voice.enabled
      ? 'The connection that allows calls to be taken has been lost. Please check your internet connection and then refresh the page.'
      : `Your calls campaign has been paused. Please check your internet connection and then refresh the page.`
  const unexpectedErrorMessage =
    'An unexpected error occurred. Please check your internet connection and then refresh the page.'

  // Confirm that the user's browser is supported
  if (!Device.isSupported) {
    // Browser is not supported, destroy the device and show an error
    device.destroy()

    dispatch({
      type: VoipActionType.SET_ERROR,
      payload: { error: browserNotSupportedErrorMessage },
    })
    Sentry.captureMessage('Browser not supported', {
      level: 'error',
      extra: { browser: navigator.userAgent },
    })

    return
  }

  device.on('registered', () => dispatch({ type: VoipActionType.SET_DEVICE, payload: { device } }))

  // Register on page load so users can start receiving inbound calls automatically
  device.register()

  // called when an inbound call comes in to the browser
  device.on('incoming', handleIncomingCall(dispatch))
  device.on('tokenWillExpire', () =>
    dispatch({
      type: VoipActionType.SET_SHOULD_REFRESH_TOKEN,
      payload: { shouldRefreshToken: true },
    })
  )
  device.on('error', (error: TwilioError.TwilioError) => {
    dispatch({ type: VoipActionType.SET_DEVICE, payload: { device } })
    Sentry.setContext('deviceToken', { token: device.token })

    Sentry.captureException(error)
    // TODO - after we fix all of the connection issues, only send errors to Sentry if they're unexpected (i.e. if the code doesn't have a specific case below)

    switch (error.code) {
      // Handle specific error codes
      case 20101:
        // Access Token Invalid
        // https://www.twilio.com/docs/api/errors/20101#error-20101
        dispatch({
          type: VoipActionType.SET_SHOULD_REFRESH_TOKEN,
          payload: { shouldRefreshToken: true },
        })
        break

      case 20104:
        // Access Token expired or expiration date invalid
        // https://www.twilio.com/docs/api/errors/20104#error-20104
        dispatch({
          type: VoipActionType.SET_SHOULD_REFRESH_TOKEN,
          payload: { shouldRefreshToken: true },
        })
        break

      case 31000:
        // Unknown Error (Generic Twilio Connection Error)
        // https://www.twilio.com/docs/api/errors/31000#error-31000
        dispatch({
          type: VoipActionType.SET_ERROR,
          payload: { error: connectionErrorMessage },
        })
        break

      case 31005:
        // Connection error
        // https://www.twilio.com/docs/api/errors/31005#error-31005
        dispatch({
          type: VoipActionType.SET_ERROR,
          payload: { error: connectionErrorMessage },
        })
        break

      case 31009:
        // Transport error
        // https://www.twilio.com/docs/api/errors/31009#error-31009
        dispatch({
          type: VoipActionType.SET_ERROR,
          payload: { error: connectionErrorMessage },
        })
        break

      default:
        // Unexpected error
        dispatch({ type: VoipActionType.SET_ERROR, payload: { error: unexpectedErrorMessage } })
        break
    }
  })
}

// adds event listeners to a new inbound call
const handleIncomingCall = (dispatch: React.Dispatch<VoipAction>) => (call: TwilioCall) => {
  const currentTitle = document.title
  document.title = '📞 INCOMING CALL'

  try {
    // send a fake error to Sentry to its easy to find when calls came in on Sentry replays
    Sentry.captureMessage('Incoming call', { level: 'info' })
    Sentry.setContext('callContext', {
      key: call.parameters.CallSid,
    })
  } catch (e) {
    console.log(e)
    Sentry.captureException(e)
  }

  dispatch({
    type: VoipActionType.SET_INBOUND_CALL,
    payload: { inboundCall: call },
  })
  // Trigger state changes on call events
  // This way we can reliably use call.status() in our components
  call.on('accept', (call: TwilioCall) => {
    dispatch({
      type: VoipActionType.SET_INBOUND_CALL_ACCEPTED,
      payload: { inboundCallAccepted: true },
    })

    document.title = currentTitle

    // Press 1 every second for 3 seconds
    setTimeout(() => {
      call.sendDigits('1')
      setTimeout(() => {
        call.sendDigits('1')
        setTimeout(() => {
          call.sendDigits('1')
        }, 1000)
      }, 1000)
    }, 1000)
  })
  call.on('cancel', () => {
    document.title = currentTitle
    dispatch({
      type: VoipActionType.CLEAR_INBOUND_CALL,
    })
  })
  call.on('reject', () => {
    document.title = currentTitle
    dispatch({
      type: VoipActionType.CLEAR_INBOUND_CALL,
    })
  })
  call.on('disconnect', (call: TwilioCall) => {
    document.title = currentTitle
    dispatch({
      type: VoipActionType.SET_INBOUND_CALL,
      payload: { inboundCall: call },
    })
  })
  call.on('mute', (_: void, call: TwilioCall) =>
    dispatch({
      type: VoipActionType.SET_INBOUND_CALL,
      payload: { inboundCall: call },
    })
  )
}

export const VoipProvider = ({ children }: { children: React.ReactNode }) => {
  const tenantConfig = useTenantConfig()
  const { user } = useAuth()
  const device = useScreenSize()
  const showNotification = useNotification()
  const { data: enabledCallCampaign } = useEnabledCallCampaign()
  const { pauseCampaign } = useCampaigns()

  const [voipState, voipDispatch] = useReducer(voipReducer, defaultVoipState)

  usePusherEvent({
    channel: 'private-voip',
    event: 'user-lead-created',
    onEvent: (data) => {
      voipDispatch({ type: VoipActionType.SET_USER_LEAD, payload: { userLead: data } })
    },
  })

  // Handle error notifications and campaign pause when an error occurs
  useEffect(() => {
    if (voipState.error) {
      // @ts-expect-error FIXME
      showNotification({ type: 'error', message: voipState.error })
      if (!!enabledCallCampaign && pauseCampaign) {
        pauseCampaign({ id: enabledCallCampaign.id, reason: 'unstable-connection' })
      }
      voipDispatch({ type: VoipActionType.CLEAR_ERROR })
    }
  }, [voipState.error, showNotification, enabledCallCampaign, pauseCampaign, voipDispatch])

  // Keep track of whether VoIP is allowed
  useEffect(() => {
    // VoIP is not allowed if user is impersonating
    // VoIP is not allowed in Dev Mode
    // VoIP is not allowed on mobile devices unless voice is enabled in the tenant config
    const voipAllowed =
      tenantConfig.voip.enabled &&
      !user?.impersonator?.id &&
      !voipState.devMode &&
      (device !== 'mobile' || tenantConfig.voip.voice.enabled)

    voipDispatch({ type: VoipActionType.SET_VOIP_ALLOWED, payload: { voipAllowed } })
  }, [
    user,
    tenantConfig.voip.enabled && tenantConfig.voip.voice.enabled,
    device,
    voipState.devMode,
  ])

  // Keep track of whether VoIP should be connected
  useEffect(() => {
    const voipShouldBeConnected =
      voipState.voipAllowed &&
      ((tenantConfig.voip.enabled && tenantConfig.voip.voice.enabled) || !!enabledCallCampaign)

    voipDispatch({
      type: VoipActionType.SET_VOIP_SHOULD_BE_CONNECTED,
      payload: { voipShouldBeConnected },
    })
  }, [
    voipState.voipAllowed,
    tenantConfig.voip.enabled && tenantConfig.voip.voice.enabled,
    enabledCallCampaign,
  ])

  // Keep track of whether VoIP is connected
  useEffect(() => {
    voipDispatch({
      type: VoipActionType.SET_VOIP_IS_CONNECTED,
      payload: { voipIsConnected: voipState.device?.state === Device.State.Registered },
    })
  }, [voipState.device?.state])

  // Initialize the Twilio Device when appropriate, if it's not already initialized
  useEffect(() => {
    if (
      voipState.voipShouldBeConnected &&
      voipState.microphonePermissionGranted &&
      !voipState.device
    ) {
      initDevice({ dispatch: voipDispatch, tenantConfig })
    }
  }, [voipState.voipShouldBeConnected, voipState.microphonePermissionGranted, voipState.device])

  // Connect (register) Twilio device when appropriate
  useEffect(() => {
    if (
      voipState.device &&
      voipState.voipShouldBeConnected &&
      voipState.device.state !== Device.State.Registered && // device is not registered
      voipState.device.state !== Device.State.Registering && // device is not registering
      voipState.device.state !== Device.State.Destroyed // device is not destroyed
    ) {
      // VoIP should be connected but it is not. Register the device.
      voipState.device.register()
    }
  }, [voipState.voipShouldBeConnected, voipState.voipIsConnected, voipState.device])

  // Disconnect (unregister) device when appropriate
  useEffect(() => {
    if (
      voipState.device &&
      !voipState.voipShouldBeConnected &&
      voipState.device.state === Device.State.Registered
    ) {
      // VoIP should not be connected but it is. Unregister the device.
      voipState.device.unregister()
    }
  }, [voipState.voipShouldBeConnected, voipState.voipIsConnected, voipState.device])

  // Fetch a fresh token when shouldRefreshToken is set to true
  useEffect(() => {
    if (voipState.shouldRefreshToken && voipState.voipAllowed) {
      if (voipState.device && !user?.impersonator?.id) {
        getNewVoipToken().then((token) => {
          Sentry.setContext('deviceToken', { token })
          Sentry.captureMessage('Refreshing VoIP token', {
            level: 'info',
            extra: { token },
          })
          voipState.device?.updateToken(token)
        })
      }
      voipDispatch({
        type: VoipActionType.SET_SHOULD_REFRESH_TOKEN,
        payload: { shouldRefreshToken: false },
      })
    }
  }, [voipState.shouldRefreshToken, user])

  const updateAvailableAudioDevices = async () => {
    const availableInputDevices = []
    const availableOutputDevices = []

    const devices = await navigator.mediaDevices.enumerateDevices()
    // listen for changes to the audio devices
    navigator.mediaDevices.ondevicechange = (event) => {
      updateAvailableAudioDevices()
    }

    for (const device of devices) {
      if (device.kind === 'audioinput') {
        availableInputDevices.push(device)
      }
      if (device.kind === 'audiooutput') {
        availableOutputDevices.push(device)
      }
    }

    voipDispatch({
      type: VoipActionType.SET_AVAILABLE_INPUT_DEVICES,
      payload: { availableInputDevices },
    })

    if (availableOutputDevices.length > 0) {
      voipDispatch({
        type: VoipActionType.SET_AVAILABLE_OUTPUT_DEVICES,
        payload: { availableOutputDevices },
      })
    } else {
      // Safari doesn't provide access to audio output devices. Add a dummy output device for display in the disabled dropdown.
      voipDispatch({
        type: VoipActionType.SET_AVAILABLE_OUTPUT_DEVICES,
        payload: {
          availableOutputDevices: [
            {
              deviceId: 'default',
              label: 'Default Output Device',
              kind: 'audiooutput',
              groupId: 'default',
              toJSON: () => {},
            },
          ],
        },
      })
    }
  }

  const requestMicrophonePermission = async () => {
    try {
      // Prompt for microphone permission by playing a sound
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      stream.getTracks().forEach((track) => track.stop())

      // No error thrown, so microphone permission has been granted
      voipDispatch({
        type: VoipActionType.SET_MICROPHONE_PERMISSION_GRANTED,
        payload: { microphonePermissionGranted: true },
      })
    } catch (err) {
      // Error thrown, so microphone permission has been denied
      // @ts-expect-error FIXME
      showNotification({
        type: 'caution',
        message:
          'Microphone permissions have been denied. In-browser calls will not function until you grant microphone permissions.',
      })
      voipDispatch({
        type: VoipActionType.SET_MICROPHONE_PERMISSION_GRANTED,
        payload: { microphonePermissionGranted: false },
      })
    }
    updateAvailableAudioDevices()
  }

  useEffect(() => {
    if (voipState.voipAllowed && !voipState.microphonePermissionGranted) {
      requestMicrophonePermission()
    }
  }, [voipState.voipAllowed, voipState.microphonePermissionGranted])

  const updateInputDevice = async ({ device }: { device: Device }) => {
    // Do nothing if no available input devices
    if (!voipState.availableInputDevices.length) return

    // Clear the selected input device id if it's not in the list of available input devices
    if (voipState.selectedInputDeviceId) {
      const selectedDevice = voipState.availableInputDevices.find(
        (device) => device.deviceId === voipState.selectedInputDeviceId
      )

      if (!selectedDevice) {
        voipDispatch({
          type: VoipActionType.SET_SELECTED_INPUT_DEVICE_ID,
          payload: { selectedInputDeviceId: undefined },
        })
        return
      }
    }

    // If no input device is selected then select the first available input device
    if (!voipState.selectedInputDeviceId) {
      const firstAvailableInputDevice = voipState.availableInputDevices[0]
      if (firstAvailableInputDevice) {
        voipDispatch({
          type: VoipActionType.SET_SELECTED_INPUT_DEVICE_ID,
          payload: { selectedInputDeviceId: firstAvailableInputDevice.deviceId },
        })
        return
      }
    }

    // Do nothing if the selected input device id already matches the current device id
    if (device.audio?.inputDevice?.deviceId === voipState.selectedInputDeviceId) return

    // Update the input device on the twilio device
    if (voipState.selectedInputDeviceId) {
      await device.audio?.setInputDevice(voipState.selectedInputDeviceId)
    }
  }

  useEffect(() => {
    if (voipState.device) {
      updateInputDevice({ device: voipState.device })
    }
  }, [voipState.device, voipState.selectedInputDeviceId])

  const updateOutputDevice = async ({ device }: { device: Device }) => {
    // Do nothing if no available output devices
    if (!voipState.availableOutputDevices.length) return

    // Clear the selected output device id if it's not in the list of available output devices
    if (voipState.selectedOutputDeviceId) {
      const selectedDevice = voipState.availableOutputDevices.find(
        (device) => device.deviceId === voipState.selectedOutputDeviceId
      )

      if (!selectedDevice) {
        voipDispatch({
          type: VoipActionType.SET_SELECTED_OUTPUT_DEVICE_ID,
          payload: { selectedOutputDeviceId: undefined },
        })
        return
      }
    }

    // If no output device is selected then select the first available output device
    if (!voipState.selectedOutputDeviceId) {
      const firstAvailableOutputDevice = voipState.availableOutputDevices[0]
      if (firstAvailableOutputDevice) {
        voipDispatch({
          type: VoipActionType.SET_SELECTED_OUTPUT_DEVICE_ID,
          payload: { selectedOutputDeviceId: firstAvailableOutputDevice.deviceId },
        })
        return
      }
    }

    // Update the output devices on the twilio device
    if (voipState.selectedOutputDeviceId) {
      try {
        await Promise.all([
          device.audio?.speakerDevices.set(voipState.selectedOutputDeviceId),
          device.audio?.ringtoneDevices.set(voipState.selectedOutputDeviceId),
        ])
      } catch (e) {
        if (
          e instanceof Error &&
          e.message === 'This browser does not support audio output selection'
        ) {
          // Safari doesn't support audio output selection. Do nothing.
        } else {
          console.error('Error setting output device', e)
        }
      }
    }
  }

  useEffect(() => {
    if (voipState.device) {
      updateOutputDevice({ device: voipState.device })
    }
  }, [voipState.device, voipState.selectedOutputDeviceId])

  const triggerDevModeInboundCall = () => {
    voipDispatch({
      type: VoipActionType.SET_INBOUND_CALL,
      payload: {
        inboundCall: new DevModeInboundCall(voipDispatch) as unknown as TwilioCall,
      },
    })
    voipDispatch({
      type: VoipActionType.SET_INBOUND_CALL_ACCEPTED,
      payload: { inboundCallAccepted: false },
    })
  }

  const triggerDevModeOutboundCall = ({ userLead }: { userLead: UserLead }) => {
    voipDispatch({ type: VoipActionType.SET_USER_LEAD, payload: { userLead } })
    voipDispatch({
      type: VoipActionType.SET_OUTBOUND_CALL,
      payload: {
        outboundCall: new DevModeOutboundCall(voipDispatch) as unknown as TwilioCall,
      },
    })
  }

  // initiates an outbound call and adds several event listeners to it
  const makeOutboundCall = async ({
    userLead,
    agentTwilioNumber,
    userLeadNumber,
  }: {
    userLead: UserLead
    agentTwilioNumber: string
    userLeadNumber: string
  }) => {
    if (!userLead) {
      console.error('Lead not found') // Might want to change this
      return
    }

    if (voipState.devMode) {
      return triggerDevModeOutboundCall({ userLead })
    }

    voipDispatch({ type: VoipActionType.SET_USER_LEAD, payload: { userLead } })

    try {
      const device = voipState.device
      if (!device) throw new Error('Device is not registered') // Might want to change this

      const call = await device?.connect({
        params: {
          To: userLeadNumber,
          From: agentTwilioNumber,
          userLeadId: userLead.id,
        },
      })

      voipDispatch({ type: VoipActionType.SET_OUTBOUND_CALL, payload: { outboundCall: call } })

      call.on('accept', () =>
        voipDispatch({
          type: VoipActionType.SET_OUTBOUND_CALL,
          payload: { outboundCall: call },
        })
      )
      call.on('cancel', () => voipDispatch({ type: VoipActionType.CLEAR_OUTBOUND_CALL }))
      call.on('reject', () => voipDispatch({ type: VoipActionType.CLEAR_OUTBOUND_CALL }))
      call.on('disconnect', (call: TwilioCall) =>
        voipDispatch({ type: VoipActionType.SET_OUTBOUND_CALL, payload: { outboundCall: call } })
      )
      call.on('mute', (_: void, call: TwilioCall) =>
        voipDispatch({ type: VoipActionType.SET_OUTBOUND_CALL, payload: { outboundCall: call } })
      )
      call.on('error', (e: TwilioError.TwilioError) =>
        voipDispatch({ type: VoipActionType.SET_ERROR, payload: { error: e.message } })
      )
    } catch (e: any) {
      console.error('Error making outbound call', e)
      voipDispatch({ type: VoipActionType.SET_ERROR, payload: { error: e.message } })
    }
  }

  return (
    <voipContext.Provider
      value={{
        voipState,
        voipDispatch,
        makeOutboundCall,
        triggerDevModeInboundCall,
      }}
    >
      {children}
      <IdleTimer />
    </voipContext.Provider>
  )
}

export { type VoipAction, VoipActionType }
