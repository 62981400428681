import React, { useEffect, useMemo } from 'react'
import { z } from 'zod'
import { Call as TwilioCall } from '@twilio/voice-sdk'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { chunk, map } from 'lodash'
import { Dropdown, FieldMessage, PrimaryButton, TextArea, TextField } from '@leadrilla/pulsar'
import { useStates } from '../../../hooks/queries/states'
import { useLeadStatuses } from '../../../hooks/queries/leadStatuses'
import { useVoip, VoipActionType } from '../VoipProvider'
import { useNotification } from '../../../hooks/notification'
import backend from '../../../backend'
import { OK } from '../../../constants/error_codes'
import { getLeadBirthdate } from '../../../helpers/voip'
import { useStopwatch } from 'react-timer-hook'
import * as Sentry from '@sentry/react'
import { useTenantConfig } from '../../../hooks/TenantConfig'
import { useEnabledCallCampaign } from 'src/hooks/queries/useEnabledCallCampaign'
import { Field } from '../../../types/field'
import Spinner from 'src/components/ui/icons/spinner'

const createFormSchema = ({
  outcomeRequired,
  customFields,
}: {
  outcomeRequired: boolean
  customFields?: Field[]
}) => {
  const leadFormSchema = z.object({
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    email: z.string().optional(),
    birthdate: z
      .string()
      .regex(/\d{4}-\d{2}-\d{2}/, 'Invalid date.')
      .or(z.literal('')),
    street_address: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    zip: z.string().optional(),
    notes: z.string().optional(),
    outcome: outcomeRequired ? z.string() : z.string().optional(),
  })

  const customFieldsSchema =
    customFields?.reduce(
      (acc, field) => ({
        ...acc,
        [field.machine_name]: z.string().optional(),
      }),
      {}
    ) ?? {}

  return z.object({
    ...leadFormSchema.shape,
    ...customFieldsSchema,
  })
}

const VoipInboundCallDetailsWindow = ({ inboundCall }: { inboundCall: TwilioCall }) => {
  const { voipDispatch, voipState } = useVoip()
  const { data: enabledCallCampaign } = useEnabledCallCampaign()
  const config = useTenantConfig()
  const closeOnUnconverted = config.campaigns.enabled && config.campaigns.hide_unconverted_calls

  const callStatus = inboundCall.status()

  const { totalSeconds } = useStopwatch({ autoStart: true })

  useEffect(() => {
    if (closeOnUnconverted && callStatus === TwilioCall.State.Closed) {
      if (enabledCallCampaign && totalSeconds <= 60) {
        backend
          .post('/calls/connection-status', {
            caller_number: inboundCall.parameters.From,
            campaign_id: enabledCallCampaign.id,
          })
          .then(({ body }) => {
            if (!body.connected) voipDispatch({ type: VoipActionType.CLEAR_INBOUND_CALL })
          })
      }
    }
  }, [callStatus])

  const { data: states } = useStates()
  const stateOptions = useMemo(
    () => states?.map(({ name, abbreviation }) => ({ text: name, value: abbreviation })) ?? [],
    [states]
  )

  const { data: leadStatuses } = useLeadStatuses({
    productId: enabledCallCampaign?.product_id,
    productType: 'calls', // This is a fallback for when enabledCallCampaign?.product_id is undefined (it often is)
  })
  const outcomeOptions = useMemo(
    () =>
      map(leadStatuses, ({ name }, key) => ({ text: name, value: key }))?.filter(
        ({ value }) => value !== 'NEW'
      ) ?? [],
    [leadStatuses]
  )

  const formSchema = createFormSchema({
    outcomeRequired: !!enabledCallCampaign,
    customFields: voipState.userLead?.custom_fields,
  })

  const customFieldsDefaultValues = voipState.userLead?.custom_fields?.reduce((acc, field) => {
    acc[field.machine_name] = field.value as string
    return acc
  }, {} as Record<string, string>)

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: voipState.userLead?.first_name ?? '',
      last_name: voipState.userLead?.last_name ?? '',
      email: voipState.userLead?.email ?? '',
      birthdate: voipState.userLead ? getLeadBirthdate({ userLead: voipState.userLead }) : '',
      street_address: voipState.userLead?.street_address ?? '',
      city: voipState.userLead?.city ?? '',
      state: voipState.userLead?.state ?? '',
      zip: voipState.userLead?.zip ?? '',
      ...customFieldsDefaultValues,
    },
  })

  useEffect(() => {
    if (voipState.userLead) {
      reset({
        first_name: voipState.userLead.first_name ?? '',
        last_name: voipState.userLead.last_name ?? '',
        email: voipState.userLead.email ?? '',
        birthdate: getLeadBirthdate({ userLead: voipState.userLead }),
        street_address: voipState.userLead.street_address ?? '',
        city: voipState.userLead.city ?? '',
        state: voipState.userLead.state ?? '',
        zip: voipState.userLead.zip ?? '',
      })
    }
  }, [voipState.userLead, reset])

  const sendNotification = useNotification()

  const submitData = async (data: z.infer<typeof formSchema>) => {
    if (!voipState.userLead?.lead_id) {
      // @ts-expect-error FIXME
      sendNotification({ type: 'error' })
      // Throw sentry error
      Sentry.captureMessage('User lead not found in Inbound Call Details Window')
      if (enabledCallCampaign)
        Sentry.setContext('call data', { 'From phone': inboundCall.parameters.From })
    } else if (voipState.userLead?.lead_id) {
      // Extract custom fields from data to separate them from lead data
      const userLeadData = {
        ...data,
        custom_fields: voipState.userLead?.custom_fields?.reduce(
          (acc, field) => ({
            ...acc,
            [field.machine_name]: data[field.machine_name as keyof typeof data],
          }),
          {}
        ),
      }
      const { status } = await backend.put(`/leads/${voipState.userLead.lead_id}`, {
        ...userLeadData,
        resume_campaign_id: enabledCallCampaign?.id,
      })

      if (status !== OK) {
        // @ts-expect-error FIXME
        sendNotification({ type: 'error' })

        Sentry.captureMessage('Error updating lead in Inbound Call Details Window')
        if (enabledCallCampaign)
          Sentry.setContext('call data', { 'From phone': inboundCall.parameters.From })
      }
    }

    voipDispatch({ type: VoipActionType.CLEAR_INBOUND_CALL })
  }

  return (
    <div className="flex h-full gap-[24px]">
      <div className="flex max-h-[400px] w-1/2 flex-col gap-[24px] overflow-y-auto">
        <div className="flex gap-[24px]">
          <TextField label="First Name" {...register('first_name')} />
          <TextField label="Last Name" {...register('last_name')} />
        </div>
        <TextField label="Street Address" {...register('street_address')} />
        <TextField label="City" {...register('city')} />
        <div className="z-50 flex gap-[24px]">
          <Controller
            name="state"
            control={control}
            render={({ field }) => {
              const { ref, ...props } = field
              return (
                <Dropdown
                  label="State"
                  placeholder="Select a state"
                  options={stateOptions}
                  optionsMaxHeight="150px"
                  {...props}
                />
              )
            }}
          />
          <TextField label="Postal Code" {...register('zip')} />
        </div>
        <div className="flex flex-col gap-[24px] pb-4">
          {chunk(voipState.userLead?.custom_fields ?? [], 2).map((fieldPair, index) => (
            <div key={index} className="flex gap-[24px]">
              {fieldPair.map((field: Field) => (
                <TextField
                  className="w-1/2"
                  key={field.machine_name}
                  label={field.name}
                  {...register(
                    field.machine_name as keyof z.infer<ReturnType<typeof createFormSchema>>
                  )}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="flex w-1/2 flex-col gap-[24px]">
        <div className="flex flex-row gap-[24px]">
          <TextField className="w-1/2" label="Email Address" {...register('email')} />
          <TextField
            className="w-1/2"
            type="date"
            label="Date of Birth"
            tone={errors['birthdate' as keyof typeof errors] && 'negative'}
            message={errors['birthdate' as keyof typeof errors]?.message}
            {...register('birthdate')}
          />
        </div>
        <div>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => {
              // @ts-ignore
              return <TextArea className="h-[109px]" rows={4} label="Notes" {...field} />
            }}
          />
        </div>
        <Controller
          name="outcome"
          control={control}
          render={({ field }) => {
            const { ref, ...props } = field
            return (
              <div>
                <Dropdown
                  label="Outcome"
                  secondary={enabledCallCampaign ? 'Required' : ''}
                  placeholder="Select an outcome"
                  options={outcomeOptions}
                  optionsMaxHeight="150px"
                  native
                  {...props}
                />
                {errors['outcome' as keyof typeof errors] && (
                  <div className="mt-[8px]">
                    <FieldMessage tone="negative">Please select an outcome.</FieldMessage>
                  </div>
                )}
              </div>
            )
          }}
        />
        <PrimaryButton
          fullWidth
          disabled={inboundCall.status() !== TwilioCall.State.Closed || isSubmitting}
          onClick={handleSubmit(submitData)}
          loading={isSubmitting}
        >
          {isSubmitting ? (
            <div className="flex items-center justify-center">
              <Spinner className="mr-2 size-5" color="white" />
              <span>Saving...</span>
            </div>
          ) : (
            'Save & continue'
          )}
        </PrimaryButton>
      </div>
    </div>
  )
}

export default VoipInboundCallDetailsWindow
